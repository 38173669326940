
import Vue from "vue";
import addrCom from "@/components/AddrCom.vue";
import dialogCom from "@/components/ModelCom.vue";
import { StoreTypes } from "@/assets/js/Types";
import HttpUtil from "@/assets/js/HttpUtil";
import Urls from "@/assets/js/Urls";
export default Vue.extend({
  components: { addrCom, dialogCom },
  props: ["current", "formData", "title"],
  data() {
    return {
      projectForm: {} as any,
      deviceTypes: this.$store.state.device[StoreTypes.XC_DEVICE_TYPE].filter(
        (x: any) => x.categorySid == 1
      ),
      routers: this.$store.state.device[StoreTypes.XC_DEVICE_ROUTER],
    };
  },
  watch: {
    formData: {
      deep: true,
      immediate: true,
      handler(newValue, oldView) {
        this.projectForm = this.formData;
        console.log(this.projectForm);
      },
    },
  },
  methods: {
    opened() {
      setTimeout(() => {
        const addr: any = this.$refs.addr;
        if (!this.current) {
          addr.init(null);
        } else {
          addr.init({
            addr: this.current.addr,
            lat: this.current.lat,
            lng: this.current.lng,
          });
        }
      }, 1000);
    },
    closeDialog() {
      const dialog: any = this.$refs.projectDialog;
      dialog.hiden();
    },
    showDialog() {
      const dialog: any = this.$refs.projectDialog;
      dialog.show();
    },

    async onSubmit() {
      const addr: any = this.$refs.addr;
      const data: any = addr.getData();
      this.projectForm.lat = data.lat;
      this.projectForm.lng = data.lng;
      this.projectForm.addr = data.addr;
      this.projectForm.curValue *= 1;
      this.projectForm.volValue *= 1;
      this.projectForm.totalPower *= 1;
      this.projectForm.loopCurRadio *= 1;
      this.projectForm.ammeterMagnification *= 1;
      this.projectForm.polling *= 1;
      this.projectForm.offlinePolicy = 1;
      if (!this.projectForm.sid) {
        await HttpUtil.XCHttpPost(Urls.AddGateway, this.projectForm, true, false);
      } else {
        await HttpUtil.XCHttpPost(Urls.UpdateGateway, this.projectForm, true, false);
      }
      this.closeDialog();
      this.$emit("submit");
    },
  },
});
